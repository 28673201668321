import SearchPage from "../views/SearchPage";
import DotpayForm from "../components/Dotpay/Form";
import RechargeAccount from "../views/Users/RechargeAccount";
import ActivityForm from "../views/Users/Activities/Form";
import ActivityTemplatesFormPage from "../views/Users/ActivitiesTemplate";
import ActivitiesTablePage from "../views/Users/Activities";
import SportLevelPage from "../views/Users/SportLevelPage";
import ParticipantsList from "../views/Users/Activities/List";
import MyTransactionsPage from "../views/Users/TransactionsPage";
import {RechargeBalanceForm} from "../views/Users/RechargeAccount/Balance";
import {ReturnDepositForm} from "../views/Users/RechargeAccount/DepositReturn";
import {RechargeDepositForm} from "../views/Users/RechargeAccount/Deposit";
import AccountPage from "../views/Users/ProfilePage";
import EditProfile from "../views/Users/EditProfile";
import SecurityPage from "../views/Users/SecurityPage";
import RestorePassword from "../views/Users/RestorePassword";
import {ShoppingCart} from "../views/ShoppingCartPage";
import CatchAllRoute from "../views/Pages/builder";
import Page from "../views/Pages";
import ObservedActivitiesPage from "../views/ObservedActivities";
import RentAObject from "../views/LandingPages/RentAObject";
import ErrorPage from "../views/ErrorPage/ErrorPage";
import BoughtActivitiesPage from "../views/BoughtActivities";
import AboutUsPage from "../views/AboutPage";
import ActivityPage from "../views/ActivityPage";
import React, {Suspense} from "react";
import LoggedInMainPage from "../views/MainPage/LoggedInMainPage";
import MainPage from "../views/MainPage/MainPage";
import ConfirmAccountPage from "../views/Users/ConfirmAccountPage/ConfirmAccountPage";
import LoadingPage from "../views/LoadingPage/LoadingPage";
import {  Route, Switch } from "react-router";
import EditBenefit from "../views/Users/Benefits";
import CompetitionGroupsPage from "../views/Users/CompetitionGroups";
import CompetitionGroupsFormIndex from "../views/Users/CompetitionGroups/Form";
import {CompetitionGroupsDashboard} from "../views/Users/CompetitionGroups/Dashboard";
import {TeamProfile} from "../views/Users/CompetitionGroups/Dashboard/Teams/TeamProfile";
import GroupPage from "../views/GroupPage";
import SubAccountsPage from "../views/Users/SubAccounts";

export const Routes = ({updateMasterState, state, ...props}) => {
    return  <Switch>
        <Route
            path="/szukaj/"
            component={() => (
                <SearchPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />
        <Route
            path="/dotpay/:id"
            component={(props) => (
                <DotpayForm
                    updateMasterState={updateMasterState}
                    {...props}
                    {...state}
                />
            )}
        />
        <Route
            path="/cennik/"
            component={() => (
                <RechargeAccount
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />
        <Route
            path="/dodaj-zajecia/:id?"
            component={(props) => {
                if (state.data.currentUser.id) {
                    return <ActivityForm
                        new
                        updateMasterState={updateMasterState}
                        {...props}
                        {...state}
                    />
                } else {
                    window.location.href = "/s/multisport-sporty-druzynowe-projekt-organizator"
                }
            }}
        />

        {state.data.currentUser.id && (
            <Route
                path="/dodaj-szablon"
                component={(props) => (
                    <ActivityTemplatesFormPage
                        {...props}
                        {...state}
                    />
                )}
            />
        )}



        {/*{state.data.currentUser.id && (*/}
        {/*    <Route*/}
        {/*        path="/moje-grupy/dodaj"*/}
        {/*        component={(props) => (*/}
        {/*            <CompetitionGroupsFormIndex*/}
        {/*                {...props}*/}
        {/*                {...state}*/}
        {/*            />*/}
        {/*        )}*/}
        {/*    />*/}
        {/*)}*/}


        {/*{state.data.currentUser.id && (*/}
        {/*    <Route*/}
        {/*        path="/moje-grupy/:competition_group_id/druzyna/:id"*/}
        {/*        component={(props) => (*/}
        {/*            <TeamProfile*/}
        {/*                {...props}*/}
        {/*                {...state}*/}
        {/*            />*/}
        {/*        )}*/}
        {/*    />*/}
        {/*)}*/}


        {/*{state.data.currentUser.id && (*/}
        {/*    <Route*/}
        {/*        path="/moje-grupy/:id/edycja"*/}
        {/*        component={(props) => (*/}
        {/*            <CompetitionGroupsFormIndex*/}
        {/*                {...props}*/}
        {/*                {...state}*/}
        {/*            />*/}
        {/*        )}*/}
        {/*    />*/}
        {/*)}*/}

        {/*{state.data.currentUser.id && (*/}
        {/*    <Route*/}
        {/*        path="/moje-grupy/:id"*/}
        {/*        component={(props) => (*/}
        {/*            <CompetitionGroupsDashboard*/}
        {/*                {...props}*/}
        {/*                {...state}*/}
        {/*            />*/}
        {/*        )}*/}
        {/*    />*/}
        {/*)}*/}


        {/*{state.data.currentUser.id && (*/}
        {/*    <Route*/}
        {/*        path="/moje-grupy/"*/}
        {/*        component={(props) => (*/}
        {/*            <CompetitionGroupsPage*/}
        {/*                {...props}*/}
        {/*                {...state}*/}
        {/*            />*/}
        {/*        )}*/}
        {/*    />*/}
        {/*)}*/}

        {state.data.currentUser.id && (
            <Route
                path="/zajecia-edycja/:id?"
                component={(props) => (
                    <ActivityForm
                        updateMasterState={updateMasterState}
                        {...props}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/moje-zajecia/"
                component={() => (
                    <ActivitiesTablePage
                        creator
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/moje-szablony/"
                component={() => (
                    <ActivitiesTablePage
                        templates
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/moje-poziomy/"
                component={() => (
                    <SportLevelPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/lista-obecnosci/:id?"
                component={(props) => (
                    <ParticipantsList
                        updateMasterState={updateMasterState}
                        {...props}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/koordynowane-zajecia/"
                component={() => (
                    <ActivitiesTablePage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/transakcje/"
                component={() => (
                    <MyTransactionsPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/doladowanie-konta/:id?"
                component={(props) => (
                    <RechargeBalanceForm
                        updateMasterState={updateMasterState}
                        {...props}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/powiazane-konta/"
                component={() => (
                    <SubAccountsPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/zwrot-kaucji/"
                component={() => (
                    <ReturnDepositForm
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/doladowanie-kaucji/"
                component={() => (
                    <RechargeDepositForm
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/account-settings"
                component={() => (
                    <AccountPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/benefits-settings"
                component={() => (
                    <EditBenefit
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/edit-profile"
                component={() => (
                    <EditProfile
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {state.data.currentUser.id && (
            <Route
                path="/security-settings"
                component={() => (
                    <SecurityPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        {!state.data.currentUser.id && (
            <Route
                path="/users/password/edit"
                component={() => (
                    <RestorePassword
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        {state.data.currentUser.id && (
            <Route
                path="/koszyk/"
                component={() => (
                    <ShoppingCart
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        <Route
            path="/p/*"
            component={() => (
                <CatchAllRoute
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />

        <Route
            path="/s/*"
            component={() => (
                <Page
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />

        {state.data.currentUser.id && (
            <Route
                path="/moja-lista-rezerwowych/"
                component={() => (
                    <ObservedActivitiesPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}

        <Route
            path="/wynajmij-obiekt/"
            component={() => (
                <RentAObject
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />

        {state.data.currentUser.id && (
            <Route
                path="/wykupione-zajecia"
                component={() => (
                    <BoughtActivitiesPage
                        updateMasterState={updateMasterState}
                        {...state}
                    />
                )}
            />
        )}
        <Route
            path="/poznaj-nas/"
            component={() => (
                <AboutUsPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />
        <Route
            path="/g/"
            component={() => (
                <GroupPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />
        <Route
            path="/z/"
            component={() => (
                <ActivityPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />

        <Route
            path="/404"
            component={() => (
                <ErrorPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />

        <Route
            exact
            path="/"
            component={() => (
                <Suspense fallback={<div></div>}>
                    {
                        state.data.currentUser.id ? <LoggedInMainPage updateMasterState={updateMasterState}
                                                                           {...state} /> : <MainPage
                            updateMasterState={updateMasterState}
                            {...state}
                        />
                    }

                </Suspense>

            )}
        />
        <Route
            path="/users/confirmation"
            component={() => (
                <ConfirmAccountPage
                    updateMasterState={updateMasterState}
                    {...state}
                />)}
        />
        <Route
            component={() => (
                <LoadingPage
                    updateMasterState={updateMasterState}
                    {...state}
                />
            )}
        />
    </Switch>
}