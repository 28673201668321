import { withFormik } from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import { Api } from "PNH/services/Api";


export const UsersFunctions = {
  add: (values, formik) => {
    return Api.basket.add({ basket_item: values }).then((response) => {
      if (response.ok) {
        formik.setValues({
          ...UsersFunctions.defaultValues(formik.props),
          ...response.data,
        });
        window.setNotifications([
          { type: "success", msg: "Dodano do koszyka" },
        ]);
      } else {
        window.setNotifications([
          {
            type: "alert",
            msg:
              "Nie udało się dodać do koszyka lub zajęcia zostały już dodane",
          },
        ]);
      }
    });
  },

  buildPlayers: (props) =>{
    const { activity } = props
    const currentUser = props.data.currentUser
    if (!!!props.data.currentUser.id) {
      return []
    }
    const toRegister = activity.bought === false ? [{ id: currentUser.id, label: currentUser.label, sport_levels: currentUser.user_sport_levels_attributes, age: currentUser.age }] : []
    const playersList = toRegister.concat(props.data.currentUser.users_can_registers)

    const user_activity_participants = activity.user_activity_participants.map(
      (p) => {
        return p.id;
      }
    );

    return !playersList
      ? []
      : playersList.filter((u) => {
        const sportLevels = !!!u.sport_levels ? [] : u.sport_levels

        const userSportLevel = sportLevels.filter((row) => row.sport_id == activity.sport.id)[0]

        if (!user_activity_participants.includes(u.id)) {
          if (u.age < activity.min_age  || u.age > activity.max_age ) {
            return false
          }
          if (activity.competition_type == 'sparing' && currentUser.id !== u.id) {
            return false
          }
          if (!activity.verify_lv && !!!userSportLevel) {
            return true
          }
          if (activity.verify_lv && !!!userSportLevel) {
            return false
          }
          if (activity.lv == 'free' || !!!activity.lv) {
            return true
          }
          if (userSportLevel.level == 'blocked') {
            return false
          }
          if (activity.verify_lv) {
            return userSportLevel.level == activity.lv
          }
          if (activity.activity_type == 'pnh' && userSportLevel.check_on_pnh) {
            return userSportLevel.level == activity.lv
          }
          if (activity.activity_type == 'organizer' && userSportLevel.check_on_organizer) {
            return userSportLevel.level == activity.lv
          }
          return true;
        }
        return false;
      })
  },

  selectCurrentUser: (players, props) => {
    const { values,  data} = props
    const currentUser = data.currentUser
    const currentPlayer = players.filter((row) => row.id == currentUser.id)
    if (currentPlayer[0]) {
      return  [currentUser.id]
    }
    return []
  },

  defaultValues: (props) => {
    let players = UsersFunctions.buildPlayers(props)
    return {
      activity_id: props.activity.id,
      participant_ids: UsersFunctions.selectCurrentUser(players, props),
      players: players,
      terms: '',
      afterSave: () => {},
    };
  },
};

export const AddToBasket = withFormik({
  validationSchema: validationSchema,
  mapPropsToValues: (props) => {
    return { ...UsersFunctions.defaultValues(props) };
  },
  handleSubmit: (values, formikProps) => {
    formikProps.setSubmitting(true);

    UsersFunctions.add(values, formikProps)
      .then((response) => {
        formikProps.props.afterSave();
        values.afterSave(response);
      })
      .then(() => {
        formikProps.setTouched({});
        formikProps.setSubmitting(false);
      });
  },
})(Form);
